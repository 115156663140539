import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import WhatPage from './components/WhatPage/WhatPage';
import PrivacyPolicy from './components/PrivacyPolicyPage/PrivacyPolicy';
import Features from './components/FeaturesPage/Features';
import Tutorials from './components/TutorialsPage/Tutorials';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AppProvider } from './context/AppContext';

const router = createBrowserRouter([
    { path: '/', element: <App /> },
    { path: 'what', element: <WhatPage /> },
    { path: 'privacypolicy', element: <PrivacyPolicy /> },
    { path: 'features', element: <Features /> },
    { path: 'tutorials', element: <Tutorials /> },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppProvider>
        <RouterProvider router={router} />
    </AppProvider>
);