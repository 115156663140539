import React from 'react'
import './phone.css'

import Slider from '../slider/Slider'; 
import phone from '../../images/Phone.png'; 
import slide2 from '../../images/InningBreak.PNG'; 
import slide4 from '../../images/Pregame.PNG';
import slide1 from '../../images/Walkout.PNG';
import slide3 from '../../images/Situational.PNG';

const slides = [
    { url: slide1 },
    { url: slide2 },
    { url: slide3 },
    { url: slide4 },
];

const Phone = () => {
    return (
        <div className='phone-img'>
            <img src={phone} alt="phone" />
            <div className="slider-container">
                <Slider slides={slides} />
            </div>
        </div>
    )
}

export default Phone