import React from 'react';
import './socials.css';

import FaSquareYoutube from "../../images/Youtube-.png";
import FaFacebookSquare  from "../../images/facebook-24x24.png";
import googlePlay from "../../images/googleplaystore.png";
import appPlay from "../../images/AppPlayStore.jpg";
import logo from '../../images/Icon-lights-512.png';

const Socials = () => {
    return (
        <>  
            <div className='logo'>
                <a href='https://upallnitesoftware.com/'><img src={logo} alt="Logo" /></a>
            </div>
            <div className='social-container'>
                <div className='youtube'>
                    <a href='https://www.youtube.com/@WalkoutsongDJ'><img src={FaSquareYoutube} alt="Youtube Logo" /></a>
                </div>  
                <div className='youtube-link'>  
                    <a href='https://www.youtube.com/@WalkoutsongDJ'><h1>Youtube</h1></a>
                </div>
                <div className='facebook'>
                    <a href='https://www.facebook.com/walkoutsongdj'><img src={FaFacebookSquare} alt="Facebook Logo" /></a>
                </div>
                <div className='facebook-link'>
                    <a href='https://www.facebook.com/walkoutsongdj'><h1>Facebook</h1></a>
                </div>
                <div className='google-store'>
                    <a href='https://play.google.com/store/apps/details?id=com.upallnitesoftware.walkoutsongdj&pli=1'><img className='socials-image' src={googlePlay} alt="Google Playstore"/></a>
                </div>
                <div className='apple-store'>
                    <a href='https://apps.apple.com/us/app/walkout-song-dj/id741990930'><img className='socials-image' src={appPlay} alt="App-Store" /></a>
                </div>
            </div>
        </>
    );
}

export default Socials;

