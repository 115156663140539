import React, { createContext, useContext } from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
    const hostname = window.location.hostname;
    const isWalkup = hostname === 'walkupsongdj.com';

    return (
        <AppContext.Provider value={{ isWalkup }}>
            {children}
        </AppContext.Provider>
    );
};