import React from "react";
import './tutorials.css'
import { Navbar, Header } from "..";

function Tutorials() { 
    return (
        <div className="tutorials">
            <Header />
            <Navbar />
            <h2>Tutorials</h2>
            <div className="ratio ratio-4x3">
                <iframe className='videoApple' src="https://www.youtube.com/embed/TXQPqUHboH4?si=48P-ja3U4ki4H8rq" title="Apple Walkout Song Set-up Tutorial" allowFullScreen></iframe>
                <iframe className='videoAndroid' src="https://www.youtube.com/embed/kMOlTrrcdgw?si=mpTdzQZNQbV1Nxxi" title="Android Walkout Song Set-up Tutorial" allowFullScreen></iframe>
                <iframe className='videoLineup' src="https://www.youtube.com/embed/ACRZpToSjSo?si=MluhHkT-def605Zm" title="Lineup Walkout Song Set-up Tutorial" allowFullScreen></iframe>
                <iframe className='videoAnnouncements' src="https://www.youtube.com/embed/31Jsd0jd2Bw?si=38Vtbu2tssoHCCKJ" title="Announcements Walkout Song Set-up Tutorial" allowFullScreen></iframe>
            </div>
        </div>
    )
}

export default Tutorials;
