import React from "react";
import './privacypolicy.css';
import { Navbar, Header } from "..";
import { useAppContext } from '../../context/AppContext';

function PrivacyPolicy() {
    const { isWalkup } = useAppContext();

    return (
        <div className="privacypolicy">
            <Header />
            <Navbar />
            <h2>Privacy Policy</h2>
            <p className='privacyp'>
                {isWalkup ? 'Walkup Song DJ' : 'Walkout Song DJ'} requires permission to use the microphone on your 
                device to record player announcements. Those announcements are not 
                used for any other purpose. They are kept in app protected storage 
                on the device and can only be played or exported by the user.
                <br/>
                <br/>
                {isWalkup ? 'Walkup Song DJ' : 'Walkout Song DJ'} requires permission to use your device music and 
                audio file collections to play selected walkout songs. Songs selected 
                or played are not tracked by any means or for any purpose.
                <br/>
                <br/>
                {isWalkup ? 'Walkup Song DJ' : 'Walkout Song DJ'} requires permission to use your Google Drive or iCloud 
                account to automatically backup and allow restore of your song and player 
                setup. The data stored in your cloud account is only accessible by the {isWalkup ? 'Walkup Song DJ' : 'Walkout Song DJ'} app installed on your devices and is not shared with any outside entity.
                <br/>
                <br/>
                On Android devices, {isWalkup ? 'Walkup Song DJ' : 'Walkout Song DJ'} requires access to your Google email 
                address in order to access your Google Drive for automated backup and restores. 
                Google should really be sophisticated enough to pass a token to the {isWalkup ? 'Walkup Song DJ' : 'Walkout Song DJ'} app that could be used to open the Google Drive account instead 
                of exposing your email address, but they do not. Rest assured, though, that your 
                Google email address is not used for any other purpose. It is not stored or shared with any other outside entity.
            </p> 
        </div>
    );
}

export default PrivacyPolicy;