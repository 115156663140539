import React from 'react'
import './header.css'

import baseballPlayer from '../../images/baseball-batter-chalk.jpg';
import softballPlayer from '../../images/softball-batter-chalk.jpg';
import baseballAnthem from '../../images/baseball-ANTHEM.jpg';
import cricketPlayer from '../../images/cricket-batsmen2.png';
import hockeyPlayer from '../../images/146879.jpeg';



const Header = () => {
    return (
        <div className='header-container'>
            <img className='baseball' src={baseballPlayer} alt="Baseball Player" />
            <img className='softball' src={softballPlayer} alt="Softball Player" />
            <img className='anthem' src={baseballAnthem} alt="Baseball Anthem" />
            <img className='cricket' src={cricketPlayer} alt="Cricket Player" />
            <img className='hockey' src={hockeyPlayer} alt="Hockey Player" />
        </div>
    )
}

export default Header