import React, { useState, useLayoutEffect, useRef } from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';

const Navbar = ({ isWalkup }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    useLayoutEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <nav>
            <div className='navbar-container'>
                <div className='hamburger' onClick={toggleMenu}>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                </div>
                <div ref={menuRef} className={`menu ${isOpen ? 'open' : ''}`}>
                    <a><Link to='/' onClick={closeMenu}>Home</Link></a>
                    <a><Link to='/what' onClick={closeMenu}>
                        {isWalkup ? 'What is a Walkup Song?' : 'What is a Walkout Song?'}
                    </Link></a>
                    <a><Link to='/features' onClick={closeMenu}>Features</Link></a>
                    <a><Link to='/tutorials' onClick={closeMenu}>Tutorials</Link></a>
                    <a href='https://upallnitesoftware.com/forum/' onClick={closeMenu}>Support Forum</a>
                    <a><Link to='/privacypolicy' onClick={closeMenu}>Privacy Policy</Link></a>
                </div>
                <div className='white-line'>
                    <hr/>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;