import React from "react";
import { Navbar, Header } from "..";
import { useAppContext } from '../../context/AppContext';
import './whatpage.css';

const WhatPage = () => {
    const { isWalkup } = useAppContext();

    return (
        <div className="whatpage">
            <Header />
            <Navbar isWalkup={isWalkup} />
            <h2 className='title'>
                {isWalkup ? 'What is a Walkup Song?' : 'What is a Walkout Song?'}
            </h2>
            <p>
                There are many names for this little chunk of music played over the ballpark 
                speakers as the batter steps up to take his turn at the plate. Walk-up song, 
                walk-out song, batting music, at-bat music, and entrance music can all be 
                freely interchanged with optional hyphens wherever they seem to fit.
                <br/><br/>
                Whatever you call it, the walkout song has become an integral part of the 
                musical score that accompanies every Major League baseball game. Players 
                take their entrance music about as seriously as they would picking out a 
                new glove or bat. In a game that is famously 90 percent mental, setting 
                the right frame of mind with a personal choice of music makes perfect sense. 
                Changing your walkout song to get out of a batting slump is a pretty common 
                practice among major leaguers with plenty of anecdotal evidence that it actually works.
                <br/><br/>
                The Major League Baseball office even has rules regarding walkout songs:
                "Any musical clip used to introduce a batter should start immediately after 
                the public address announcement and should stop when the batter reaches the 
                dirt cut-out surrounding home plate. Music clips between pitches should be 
                limited in order not to encourage the hitter to leave the batter's box."
                <br/><br/>
                The walkout song trend has now found solid footing in the college landscape. 
                Most intercollegiate teams competing in both softball and baseball will have 
                a song slice prepared for every batter in their varsity lineup. It has even 
                become pretty common to hear walkout songs at high school games.
                <br/><br/>
                Playing the walkout music at a Major League ballpark is a broadcasting team 
                effort with professional public address equipment at easy disposal, but things
                are much more difficult for the parent, assistant coach or team manager that 
                gets selected to produce the music for a high school or college game.
                <br/><br/>
                That is the position that I found myself in a couple years back when my daughter
                was in her first year of high school softball, and that is why I wrote the
                Walkout Song DJ app. The app has evolved a little over the years to cover all 
                the music needs for the game and I think you will find that it makes this complicated 
                but critical role much easier to fill.
                <br/><br/>
                Thanks for your interest in Walkout Song DJ,
                Joe Vidunas
            </p>
        </div>
    );
}

export default WhatPage;