import React from "react";
import './features.css';
import { Navbar, Header } from "..";
import { useAppContext } from '../../context/AppContext';

function Features() {
    const { isWalkup } = useAppContext();

    return (
        <div className="features">
            <Header />
            <Navbar isWalkup={isWalkup}/>
            <h2>Features</h2>
            <p className='featuresp'>
                Music makes the ball field come to life, but until now being the ballpark DJ wasn't easy.
                <br />
                <br />
                {isWalkup ? (
                    <>
                        Walkup Song DJ is here to make it simple to have every baseball and softball field filled with that pump-up music every athlete loves and needs for those big games.
                    </>
                ) : (
                    <>
                        Walkout Song DJ is here to make it simple to have every baseball and softball field filled with that pump-up music every athlete loves and needs for those big games.
                    </>
                )}
                <br />
                <br />
                {isWalkup ? (
                    <>
                        Walkup Song DJ makes the difficult task of playing a walkup song for each individual batter as easy as tapping a button. Simply enter the player's name and number, then select a song from your music library. The precise segment from that walkup song can then be set by selecting a position and playback duration using a simple and precise editing view. To assure that the songs are not blending into the background or splitting the ears of your fans, a volume level for each individual walkup song can also be set. Press the play button and 11 seconds later, you have one pumped-up batter and a song that smoothly fades as the batter steps up to the plate.
                    </>
                ) : (
                    <>
                        Walkout Song DJ makes the difficult task of playing a walkout song for each individual batter as easy as tapping a button. Simply enter the player's name and number, then select a song from your music library. The precise segment from that walkout song can then be set by selecting a position and playback duration using a simple and precise editing view. To assure that the songs are not blending into the background or splitting the ears of your fans, a volume level for each individual walkout song can also be set. Press the play button and 11 seconds later, you have one pumped-up batter and a song that smoothly fades as the batter steps up to the plate.
                    </>
                )}
                <br />
                <br />
                The game doesn't stop between innings, so why should the music? 
                {isWalkup ? ' Walkup Song DJ ' : ' Walkout Song DJ '} 
                has a view dedicated to inning break songs. Just as before, select a song from your music library and set a position and duration. Since inning breaks are usually two minutes long and most songs are over three minutes, 
                {isWalkup ? ' Walkup Song DJ ' : ' Walkout Song DJ '}
                can automatically fade the song out before the start of play. That allows the human DJ to take a break or get a hot dog, and not have to worry about the music interrupting the game.
                <br />
                <br />
                There is also one-touch access to a situational music view. These are the songs that add character to the game, such as the Star-Spangled Banner, Take Me Out to the Ball Game, Jeopardy Theme Song, your team fight song, and anything else that fits!
                <br />
                <br />
                For pre-game music, there is a pre-game view that allows you to select a group of songs from your library and arrange them in a simple playlist to play without interruption and help the athletes get “in the zone” during warm-ups.
                <br />
                <br />
                If you happen to be a dedicated ballpark DJ and have multiple teams to DJ for, don't sweat it. 
                {isWalkup ? ' Walkup Song DJ ' : ' Walkout Song DJ '}
                makes it possible to create and save song pages for multiple teams.
                <br />
                <br />
                So sit back, relax, and enjoy the game while playing the all-important music by barely lifting a finger.
            </p>
        </div>
    );
}

export default Features;