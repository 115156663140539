import React from 'react';
import './description.css';

const Description = ({ descriptionText }) => {
    return (
        <div className='description-container'>
            <h2 className='desc-title'>UpAllNite Software Presents</h2>
            <br/>
            <br/> 
            <p className='desc-p'>
                {descriptionText}
            </p>
        </div>
    );
}

export default Description;