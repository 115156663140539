import React, { useEffect } from 'react';
import './App.css';
import { Description, Header, Navbar, Phone, Socials } from './components';

const App = () => {
    const hostname = window.location.hostname;

    const domainMapping = {
        'walkoutsongdj.com': {
            title: 'Walkout Song DJ - Simplify your baseball and softball team music',
            description: 'Walkout Song DJ is the easiest, most intuitive way to manage all the music for a baseball or softball game. The only mobile music app designed specifically for the ballpark, Walkout Song DJ allows the team audiophile to quickly and easily script the walk-up music for every player on the roster, the songs between innings, and all the other unique ballpark sounds.',
            isWalkup: false
        },
        'walkupsongdj.com': {
            title: 'Walkup Song DJ - Simplify your baseball and softball team music',
            description: 'Walkup Song DJ is the easiest, most intuitive way to manage all the music for a baseball or softball game. The only mobile music app designed specifically for the ballpark, Walkup Song DJ allows the team audiophile to quickly and easily script the walk-up music for every player on the roster, the songs between innings, and all the other unique ballpark sounds.',
            isWalkup: true
        },
    };

    const defaultMapping = {
        title: 'Walkout Song DJ - Simplify your baseball and softball team music',
        description: 'Walkout Song DJ is the easiest, most intuitive way to manage all the music for a baseball or softball game. The only mobile music app designed specifically for the ballpark, Walkout Song DJ allows the team audiophile to quickly and easily script the walk-up music for every player on the roster, the songs between innings, and all the other unique ballpark sounds.',
        isWalkup: false
    };

    const { title, description, isWalkup } = domainMapping[hostname] || defaultMapping;

    useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <div className="App">
            <Header />
            <Navbar isWalkup={isWalkup} />
            <Description descriptionText={description} />
            <Phone /> 
            <Socials />
        </div>
    );
}

export default App;