import React, { useState, useLayoutEffect } from 'react';
import './slider.css';

const Slider = ({ slides }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useLayoutEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [slides.length]);

    return (
        <div className="slider">
            <div className="slider-content" style={{ backgroundImage: `url(${slides[currentIndex].url})` }}>
                {/* Arrows removed */}
            </div>
            <div className="slider-dots">
                {slides.map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
};

export default Slider;